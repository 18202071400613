// eslint-disable-next-line no-restricted-imports
import {
  useLocation,
  useNavigate as useNavigateLib,
  useRouter,
  useMatch,
  useMatchRoute,
  PartialGenerics,
  DefaultGenerics,
  BuildNextOptions,
} from "@tanstack/react-location";
import { useCallback, useEffect, useMemo, useRef } from "react";
import type { Transition } from "history";

import { useLoadStatus } from "common/load/hooks";
import { WithStringValues } from "common/utils/types";
import { parseNumber } from "common/utils/numbers";

import { Destination, PathData, PluckDestination } from "./types";

export { useRouter, useLocation, useMatch, useMatchRoute };

/**
 * the matching infastructure appears to be in-sync, but the location returned
 * by `useLocation` appears to be a bit ahead (in-line with when the url changes).
 * let's do a match on any path to check for chagnes
 */
export const useCurrentMatchedPath = () => {
  const matchRoute = useMatchRoute();
  return (matchRoute({ to: "/*" }) || {})["*"];
};

/**
 * React-location is extermely eager to update location.current and doesn't track pending
 * state until some later point in time after navigation.
 * This detects if navigate has been called but the route hasn't been loaded yet to make
 * up for these deficiencies.
 *
 * @returns isTransitioning - If the pending path doesn't match the current matched path
 *
 */
export const useIsTransitioning = () => {
  const location = useLocation();
  const currentMatchedPath = useCurrentMatchedPath();

  return location.current.pathname !== (currentMatchedPath && `/${currentMatchedPath}`);
};

/**
 * Like react-location [useSearch](https://react-location.tanstack.com/docs/api#usesearch)
 * But is stable across re-renders, and doesn't update before the next route has been matched.
 *
 * It appears these may be some bugs in react-location because their documentation says that it
 * should be stable across renders as is, and I can't imagine it's intentionally for part
 * of the exposed location to update on navigation and not others.
 *
 * We may be able to remove this hook down the road if that bug is fixed
 */
export const useSearch = <TPath extends PartialGenerics & { search: {} }>(): TPath["Search"] => {
  const location = useLocation();
  const isTransitioningPath = useIsTransitioning();
  const lastSearch = useRef({ value: {}, str: "" });
  return useMemo(() => {
    // if we are not in the same route, let's immediately propagate search changes.
    // Otherwise match is a bit delayed and we want to keep rendering the previous
    // search until we are no longer in a pending state
    if (!isTransitioningPath && location.current.searchStr !== lastSearch.current.str) {
      lastSearch.current = { value: location.current.search, str: location.current.searchStr };
    }
    return lastSearch.current.value;
    // we only care about tracking if the search string has changed
    // or the route has stoped transitioning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.current.searchStr, isTransitioningPath]);
};

/**
 * Returns a function that when given a destinations returns the URL that
 * destination represents.
 */
export const useBuildNext = <TPath extends PartialGenerics = PathData>() => {
  const location = useLocation<TPath>();
  return useCallback(
    (dest: Destination<TPath>) => {
      const next = location.buildNext("", dest);
      return next.pathname + next.searchStr + next.hash;
    },
    [location],
  );
};

type LibNavigateOpts<TPath extends PartialGenerics> = BuildNextOptions<TPath> & {
  replace?: boolean | undefined;
  fromCurrent?: boolean | undefined;
};
type NavigateOpts<TPath extends PartialGenerics> = PluckDestination<LibNavigateOpts<TPath>>;
type Navigate<TPath extends PartialGenerics = DefaultGenerics> = (
  dest: Destination<TPath, TPath["Search"]>,
  opts?: NavigateOpts<TPath>,
) => void;

/**
 * Just like the standard `useNavigate` but will also perform
 * a hard redirect if the current loaded page is considered stale
 *
 * @see {@link load/LoadStatusProvider!LoadStatusProvider} for more information on
 * how load status is determined.
 * @see [useNavigate](https://react-location.tanstack.com/docs/api#usenavigate) for library documentation
 */
export const useNavigate = <TPath extends PartialGenerics = PathData>(): Navigate<TPath> => {
  const navigateLib = useNavigateLib<TPath>();
  const buildNext = useBuildNext();
  const { isStale } = useLoadStatus();

  // store dependncies in a ref so we don't have to change the identity
  // of navigate when this value changes. We don't want to trigger
  // navigation multiple times if a consumer is listening for `navigate` to change
  const dependencyRef = useRef({ navigateLib, buildNext, isStale });

  useEffect(() => {
    dependencyRef.current = { navigateLib, buildNext, isStale };
  }, [navigateLib, buildNext, isStale]);

  return useCallback((dest, opts) => {
    const dependencies = dependencyRef.current;
    if (!dependencies.isStale) {
      dependencies.navigateLib({ ...dest, ...opts });
    } else if (opts?.replace) {
      window.location.replace(dependencies.buildNext(dest));
    } else {
      window.location.href = dependencies.buildNext(dest);
    }
  }, []);
};

export type SetSearchFn<T extends object> = (
  updates: Partial<T>,
  opts?: {
    replace?: boolean;
  },
) => void;

/**
 *  * A hook that returns the query params and a method to help set them
 *
 * @param defaults The query params to use
 *
 * @returns An array containing the current values and a setter
 *   for those values. Values can be the original type or a string
 *   because query-string does not guarentee to perserve type information.
 *
 *   When using the setter, the full set of params should be provided.
 */
export const useSearchState = <
  T extends PartialGenerics & { Search: {} } = never,
  S extends WithStringValues<T["Search"]> = WithStringValues<T["Search"]>,
>(
  defaults: S,
): readonly [S, SetSearchFn<S>] => {
  const search = useSearch();
  const navigate = useNavigate();

  const state = useMemo(() => {
    return { ...defaults, ...search };
  }, [defaults, search]);

  // Store the defaults in a ref so that we can compare them to the updates
  // when setting the new search. This allows us to keep setState stable across
  // renders
  const defaultsRef = useRef(defaults);
  defaultsRef.current = defaults;

  const setState = useCallback<SetSearchFn<S>>(
    (updates, { replace = true } = {}) => {
      const newSearch = {
        // Always reset the page to undefined when changing other params
        page: undefined,
        // Add the updates to the search. page will be here to override if needed
        ...updates,
        // Reset the updates to undefined if they are the same as their defaults
        ...Object.fromEntries(
          Object.entries(updates).map(([key, value]) => [
            key,
            // If the value is the same as the default, reset it to undefined so that
            // the query string is not polluted with default values. Dates are converted to
            // strings before passing them in, so this is safe to do for now
            `${value}` === `${defaultsRef.current[key as keyof T["Search"]]}` ? undefined : value,
          ]),
        ),
      };
      navigate({ search: newSearch }, { replace });
    },
    [navigate],
  );

  return [state, setState] as const;
};

/**
 *
 * Can be used to arbitriarly block navigation, or to display a prompt on navigation.
 *
 * @param shouldTransition - async function called during a transition. If the function evaluates to true
 * allow the transition to complete, otherwise stop the transition.
 * @param when - if true then the block will trigger, otherwise do nothing
 *
 * Source code loosly based on react-location [usePrompt](https://react-location.tanstack.com/docs/api#useprompt)
 * but allows you to program your own condition to continue navigation.
 */
export function useBlockNavigation(
  shouldTransition: () => Promise<boolean> = async () => true,
  when: (transition: Transition | null) => boolean,
): void {
  const location = useLocation();

  // save `shouldTransition` in a ref so that if the function value
  // changes on each render we don't continually set up our history block
  const shouldTransitionRef = useRef<() => Promise<boolean>>(shouldTransition);
  useEffect(() => {
    shouldTransitionRef.current = shouldTransition;
  }, [shouldTransition]);

  useEffect(() => {
    // Short circuit here to avoid promting on refresh if needed since the history library automatically
    // adds a beforeunload event listener: https://github.com/remix-run/history/blob/dev/docs/blocking-transitions.md
    if (!when(null)) {
      return () => {};
    }

    const unblock = location.history.block(async (transition) => {
      const proceed = () => {
        unblock();
        transition.retry();
      };

      // Allow blocking the navigation based on the params
      if (!when(transition)) {
        proceed();
        return;
      }

      // Allow continuing the transition after confirming
      if (await shouldTransitionRef.current()) {
        proceed();
        return;
      }

      location.current.pathname = window.location.pathname;
    });

    return unblock;
  }, [location, when]);
}

type UseRestoreScrollProps = {
  /** A unique id for this scrollable element to allow multiple scrolls on a page */
  id: string;
  /** The ref we want to scroll since it won't always be the window */
  ref: React.RefObject<HTMLElement>;
};

const SESSION_KEY_PREFIX = "restore-scroll";

const getKey = (location: ReturnType<typeof useLocation>, id: string) => {
  const key = location.current.key ?? "default";
  return `${SESSION_KEY_PREFIX}-${key}-${id}`;
};

/**
 * Some browsers don't give access to sessionStorage, so we need to wrap it
 * in a try/catch to avoid errors that we can't do anything about. This is
 * a best effort to restore scroll position, but it's not critical to the
 * functionality of the site.
 */
const safeSessionStorage = {
  getItem: (key: string) => {
    try {
      return sessionStorage.getItem(key);
    } catch {
      console.warn("Unable to access sessionStorage.getItem");
      return null;
    }
  },
  setItem: (key: string, value: string) => {
    try {
      sessionStorage.setItem(key, value);
    } catch {
      console.warn("Unable to access sessionStorage.setItem");
    }
  },
};

/**
 * For use with native scrollable elements (like a div).
 */
export const useRestoreScroll = (props: UseRestoreScrollProps) => {
  const { id, ref } = props;

  const location = useLocation();
  const key = getKey(location, id);

  useEffect(() => {
    const element = ref.current;
    const scrollTop = safeSessionStorage.getItem(key);

    // If we have an element and a scroll position, restore it
    if (element && scrollTop) {
      element.scrollTop = parseNumber(scrollTop, 0);
    }
    // Only run on mount/unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return () => {};
    }

    return () => {
      // When unmounting the component, save the scroll position
      safeSessionStorage.setItem(key, element.scrollTop.toString());
    };
    // Only run on mount/unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

/**
 * For use with OverlayScrollbars. The instantiation of the scrollable element is deferred
 * until the browser is idle so we need separate logic to restore the scroll position.
 */
export const useRestoreOverlayScroll = (props: UseRestoreScrollProps) => {
  const { id, ref } = props;

  const location = useLocation();

  // The key is the same for the same route, so we can use a ref to avoid
  // it changing when accessing the next route during the destroy phase
  const key = getKey(location, id);
  const keyRef = useRef(key);

  const scrollRef = useRef(0);

  return useMemo(
    () => ({
      initialized: () => {
        const element = ref.current;
        const scrollTop = safeSessionStorage.getItem(keyRef.current);
        if (element && scrollTop) {
          element.scrollTop = parseNumber(scrollTop, 0);
        }
      },
      scroll: () => {
        // Update the key ref so that the destroy phase can use the correct key
        // if the params were changed
        keyRef.current = key;
        const element = ref.current;
        if (element) {
          scrollRef.current = element.scrollTop;
        }
      },
      destroyed: () => {
        safeSessionStorage.setItem(keyRef.current, scrollRef.current.toString());
      },
    }),
    [key, ref],
  );
};

const LAST_ROUTE_KEY = "last-route-path";

export const useBackTracking = () => {
  const location = useLocation();

  const last = useRef({
    path: location.current.pathname,
    search: location.current.searchStr,
  });

  useEffect(() => {
    // When the route changes, save the previous route
    const unsubscribe = location.history.listen((l) => {
      const { path, search } = last.current;

      const back = path + search;

      last.current.path = l.location.pathname;
      last.current.search = l.location.search;

      safeSessionStorage.setItem(LAST_ROUTE_KEY, back);
    });

    return unsubscribe;
  }, [location.history]);
};

type UseGoBackParams = {
  default: Destination;
};

export const useGoBack = (params: UseGoBackParams) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = useCallback(
    (opts?: { replace?: boolean }) => {
      const back = sessionStorage.getItem(LAST_ROUTE_KEY);

      // If we have something in the site to go back to, let's rely on the browser history
      // to do just that so the button works exactly like the browser back button
      if (back) {
        location.history.back();
      } else {
        navigate(params.default, opts);
      }
    },
    [location.history, navigate, params.default],
  );

  return goBack;
};
